// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import SentryRRWeb from '@sentry/rrweb'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://d05589fe172d45a58e2984ef4dfee442@o506896.ingest.sentry.io/5597207',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
  integrations: [
    new SentryRRWeb({
      // ...options
    }),
  ],
  enabled: process.env.NODE_ENV !== 'development',
})
