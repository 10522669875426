import useSWR, { mutate } from 'swr'
import { Provider as NextAuthProvider, SessionProviderOptions, setOptions } from 'next-auth/client'
import { createContext, createElement, ReactNode } from 'react'
import { Session } from 'next-auth'

type ContextType = [Session | null, boolean]

const SessionContext = createContext<ContextType>([null, true])

type Props = {
  children: ReactNode
  session?: Session
  options: SessionProviderOptions
}

export function SessionProvider({ children, session = undefined, options = {} }: Props) {
  setOptions(options)
  return createElement(SessionContext.Provider, { value: useSession(session) }, [
    createElement(NextAuthProvider, { session, options, children, key: 'next-auth-provider' }),
  ])
}

export function useSession(initialData: Session | null = null): ContextType {
  const { data = undefined, isValidating: loading } = useSWR<Session | null>('/api/auth/session', {
    initialData,
    fetcher: (url) => fetch(url).then((r) => r.json()),
  })
  const session = data?.user ? data : null

  return [session, loading]
}

export const mutateSession = (session?: Session | null, shouldRevalidate?: boolean) => {
  return mutate('/api/auth/session', session, shouldRevalidate)
}
