import chalk from 'chalk'
const { error } = console
import * as Sentry from '@sentry/nextjs'

// TODO: link this logger up with a service like Sentry [FKW-12](https://protocode.atlassian.net/browse/FKW-12)

const apiError = async (...args: Parameters<typeof error>) => {
  error('[API] - ', chalk.red(args))
  error(args)
  Sentry.captureException(args)
  await Sentry.flush(2000)
}

const clientError = (...args: Parameters<typeof error>) => {
  return (source = '') => {
    error(`[${source ? `CLIENT | ${source}` : 'CLIENT'}] - `, chalk.red(args))
    error(args)
  }
}

export const logger = {
  apiError,
  clientError,
}
