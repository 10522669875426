import { useSession } from '@eq/hooks/useSession'
import { createContext, ReactNode, useEffect, useState } from 'react'

type CordovaProps = null

export const CordovaContext = createContext<CordovaProps>(null)

interface EQCordovaPlugin {
  eq: {
    eqpush: {
      init: () => void
    }
  }
}

export function CordovaProvider(props: { children: ReactNode }) {
  const [session] = useSession()
  const [hasInitializedNative, setHasInitializedNative] = useState(false)

  const hasSession = Boolean(session?.user)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const nativePlugins = window.cordova?.plugins as EQCordovaPlugin
      if (nativePlugins && nativePlugins.eq.eqpush) {
        if (hasSession && !hasInitializedNative) {
          nativePlugins.eq.eqpush.init()
          setHasInitializedNative(true)
        } else if (!hasSession && hasInitializedNative) {
          setHasInitializedNative(false)
        }
      }
    }
  }, [hasSession, hasInitializedNative, setHasInitializedNative])

  return <CordovaContext.Provider value={null}>{props.children}</CordovaContext.Provider>
}
