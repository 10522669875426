/* eslint-disable no-console */

import { getAnalytics } from 'firebase/analytics'
import { getMessaging, getToken, onMessage, MessagePayload, Messaging } from 'firebase/messaging'
import { initializeApp, FirebaseApp, getApps } from 'firebase/app'
import localforage from 'localforage'
import cookies from 'js-cookie'

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  authDomain: 'eezyquote.firebaseapp.com',
  projectId: 'eezyquote',
  storageBucket: 'eezyquote.appspot.com',
  messagingSenderId: '327593191337',
  appId: '1:327593191337:web:95e2b30c29e32a420a86b8',
  measurementId: 'G-2TPD89B6JP',
}

const firebaseMessaging = () => {
  const enabled = Boolean(typeof window !== 'undefined' && !window.cordova && 'serviceWorker' in navigator && 'Notification' in window)
  let isInitialised = false
  let firebaseApp: FirebaseApp
  let messaging: Messaging
  let registrationToken: string | undefined

  function logger(...args) {
    console.log('[Firebase Messaging]', ...args)
  }

  async function setToken(token: string) {
    if (token) {
      cookies.set('fcm_token', token, { expires: 365 })
      await localforage.setItem('fcm_token', token)
    } else {
      cookies.remove('fcm_token')
      await localforage.removeItem('fcm_token')
    }
    return token
  }

  async function generateToken(retry = 0): Promise<string | undefined> {
    try {
      const status = Notification.permission !== 'default' ? Notification.permission : await Notification.requestPermission()
      if (status === 'granted') {
        //getting registrationToken from FCM
        registrationToken = await getToken(messaging, {
          vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VPAID,
        })
        if (registrationToken) await setToken(registrationToken)
        return registrationToken
      } else {
        logger('Permission not granted for notifications')
      }
      if (retry < 3) {
        logger('generate registrationToken retry', retry + 1)
        return await generateToken(retry + 1)
      } else {
        logger('firebase could not be initialized')
        return undefined
      }
    } catch (error) {
      console.error(error)
      logger('firebase could not be initialized')
      return undefined
    }
  }

  // INIT THE MESSAGING SERVICES
  ;(async () => {
    isInitialised = (getApps?.()?.length ?? 0) > 0
    if (!isInitialised && enabled) {
      firebaseApp = initializeApp(firebaseConfig)
      logger('App initialized')
      messaging = getMessaging(firebaseApp)
      logger('Messaging initialized')
      try {
        if (registrationToken) {
          return registrationToken
        } else {
          registrationToken = await generateToken()
          getAnalytics()
          logger('Generated app registrationToken', { registrationToken })
          return registrationToken
        }
      } catch (error) {
        console.error(error)
        return undefined
      }
    } else {
      return undefined
    }
  })()

  return {
    getEnabled: () => enabled,
    onMessage: function (callback: (payload: MessagePayload) => void) {
      if (messaging) {
        onMessage(messaging, (payload) => {
          logger('Message received', payload)
          callback(payload)
        })
      }
    },

    registerDevice: async function () {
      if (registrationToken) {
        const result = await fetch('/api/v1/register-device', { method: 'POST', body: JSON.stringify({ registrationToken }) })
        if (result.ok) logger('this device has been registered with this user')
      }
    },
    deleteDevice: async function () {
      if (registrationToken) {
        const result = await fetch('/api/v1/register-device', { method: 'DELETE', body: JSON.stringify({ registrationToken }) })
        if (result.ok) logger('this device has been unregistered')
      }
    },
  }
}

export { firebaseMessaging }
