// intercom.ts
import crypto from 'crypto'
import { loadIntercom as initIntercom, updateIntercom } from 'next-intercom'
import { hasIntercom } from 'next-intercom/dist/has-intercom'
import type { IntercomProps } from 'next-intercom/types/types'

export const loadIntercom = (props?: Omit<IntercomProps, 'appId'>) => {
  if (hasIntercom()) {
    updateIntercom('update', props)
  } else {
    initIntercom({ appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID || 'qmz0bxz6', ...(props || {}) })
  }
}

export const intercomUserHash = (id: string, secret: string) => {
  return crypto.createHmac('sha256', secret).update(id).digest('hex')
}
