import { useSession } from '@eq/hooks/useSession'
import { UserTeam } from '@eq/interfaces/users'
import { Session, User } from 'next-auth'
import Router from 'next/router'
import { useEffect } from 'react'
import useSWR from 'swr'

export function useUser({ redirectTo = '', redirectIfFound = false } = {}) {
  const [session, loading] = useSession()

  const hasSession = Boolean(session?.user)
  const isLoading = !session && loading

  useEffect(() => {
    if (!redirectTo || isLoading) return
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !hasSession) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && hasSession)
    ) {
      Router.push(redirectTo)
    }
  }, [redirectTo, redirectIfFound, hasSession, isLoading])

  return [(session?.user as User | null) ?? null, isLoading] as const
}

export function useTeams(initialUser?: Session['user'] | null) {
  const [user] = useUser()
  const { data: teams } = useSWR<UserTeam[]>(user || initialUser ? '/api/teams' : null)
  return teams ?? []
}

export function useTeam(teamId?: string) {
  const teams = useTeams()
  if (!teamId) return undefined
  const team = teams.find((team) => [team._id, team.slug].includes(teamId))
  return team
}
