import round from 'lodash/round'

if (!Number.prototype.round) {
  Number.prototype.round = function (places) {
    return round(Number(this), places)
  }
}

// old method: to investigate
// +(Math.round(Number(this + 'e+' + places)) + 'e-' + places)
