import { useUser } from '@eq/hooks/useUser'
import { UserTeam } from '@eq/interfaces/users'
import { User } from 'next-auth'
import { useRouter } from 'next/router'
import { createContext, ReactNode, useMemo } from 'react'
import { useTeams } from './useUser'

type ActiveAccount = ((UserTeam | User) & { email?: string }) | null

export const accountContext = createContext<ActiveAccount>(null)

export function useAccount(): ActiveAccount {
  const router = useRouter()
  const [user] = useUser()
  const teams = useTeams(user)

  const activeAccount = useMemo(() => {
    const query = router.query
    const team = teams?.find?.((team) => {
      // checks all dynamic paths to match if current path is in users team scope
      return [query.teamSlug, query.teamId, query.slug, query.accountId].filter(Boolean).includes(team.slug)
    })
    if (team) return team as ActiveAccount
    return user as ActiveAccount
  }, [user, teams, router.query])

  return activeAccount
}

export function AccountProvider(props: { children: ReactNode }) {
  const account = useAccount()
  return <accountContext.Provider value={account}>{props.children}</accountContext.Provider>
}
