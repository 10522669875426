import jsend, { JSendObject } from 'jsend'
import { logger } from './logger'

interface ApiReturn<T extends any> extends JSendObject<T> {
  /**
   * @deprecated use res.jsend instead (https://github.com/Prestaul/jsend)
   */
  readonly error?: string
  /**
   * @deprecated use res.jsend instead (https://github.com/Prestaul/jsend)
   */
  readonly done?: boolean
  /**
   * @deprecated use res.jsend instead (https://github.com/Prestaul/jsend)
   */
  readonly result?: T
}

const getUrl = (url: string) => {
  if (!['http', 'https'].includes(url)) {
    if (typeof window !== 'undefined') {
      return window.location.origin + url
    } else {
      return (process.env.NEXT_PUBLIC_DOMAIN || process.env.NEXT_PUBLIC_URL || '') + url
    }
  }
  return url
}

let count = 0
const logFetchEvent = (args, startTime, status) => {
  const [url, options] = args
  const endTime = new Date().getTime()
  ++count
  // eslint-disable-next-line no-console
  console.log(`#${count} -> ${options?.method || 'GET'} ${getUrl(url)} (${status}) [${endTime - startTime}ms]`)
}

export function fetcher<T = any>(...args: Parameters<typeof fetch>): Promise<ApiReturn<T>> {
  const startTime = new Date().getTime()
  return fetch(...args)
    .then<ApiReturn<T>>((res) => {
      try {
        logFetchEvent(args, startTime, res.status)
      } catch (error) {
        console.error(error)
      }
      return res.json()
    })
    .catch((err) => {
      console.error(err)
      logger.clientError(err)
      return jsend.error({ message: "We've experience a technical issue, please contact support if the issue persists." })
    })
}

export function swrFetcher<T = any>(...args: Parameters<typeof fetch>) {
  return fetcher<T>(...args).then((res) => {
    if (res.result) {
      console.warn(
        `[Deprecation] The api (${args[0]}) is still using the old apiResult method, you should migrate to res.jsend.
documentation: (https://github.com/Prestaul/jsend)`
      )
    }
    try {
      if ('status' in res && (res?.status === 'fail' || res?.status === 'error')) {
        // if there is no status it will never be jsend
        throw new Error(res.message || 'An error occurred while fetching the data.')
      }

      return res.data || res.result
    } catch (error) {
      if (res.message) {
        throw new Error(res.message)
      } else if (error instanceof Error && error.message) {
        throw new Error(error?.message)
      } else {
        throw new Error('An error occurred while fetching the data.')
      }
    }
  })
}

/**
 * @deprecated use `res.jsend` instead (https://github.com/Prestaul/jsend)
 * ```
 *  res.status(200).send(res.jsend.success("Hello World"))
 * ```
 */
export function apiResult<T = any>(result: T) {
  if (
    Object.keys(result).some((key) => {
      const invalid = !['error', 'done', 'result', 'code', 'data', 'status'].includes(key)
      if (invalid) {
        console.error(`The key: ${key} is not valid in the api results`)
      }
      const warning = !['done', 'result', 'error'].includes(key)
      if (warning) {
        console.warn(
          `[Deprecation] The api is still using the old apiResult method, you should migrate to res.jsend.
documentation: (https://github.com/Prestaul/jsend)`
        )
      }

      return invalid
    })
  ) {
    throw new Error('One or more ke')
  }
  return result
}
