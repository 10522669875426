import { useSession } from '@eq/hooks/useSession'
import { firebaseMessaging } from '@eq/lib/firebase'
import { createContext, useContext, useEffect, useState } from 'react'

const messaging = firebaseMessaging()

interface MessagingContextProps {
  messaging: typeof messaging
  notifications: any[]
  setNotifications: (notifications: any[]) => void
}

const MessagingContext = createContext<MessagingContextProps>({} as any)

export const useMessaging = () => useContext(MessagingContext)

export const MessagingProvider = ({ children }) => {
  const [session] = useSession()
  const [notifications, setNotifications] = useState<any[]>([])
  const [mounted, setMounted] = useState(false)
  const userID = session?.user?.id

  useEffect(() => {
    setMounted(true)
  }, [mounted])

  useEffect(() => {
    async function handleRegisterDevice() {
      await messaging.registerDevice()
      messaging.onMessage((payload) => {
        const title = payload?.data?.title ?? payload?.notification?.title ?? 'EezyQuote Notifications'
        const options = {
          body: payload?.data?.body || (payload?.data?.notification as any)?.body || '',
          icon: '/android-chrome-512x512.png',
          badge: '/favicon/mstile-144x144.png',
          tag: 'eezyquote-notifications',
          data: payload?.data,
        }
        if ('Notification' in window) {
          if (Notification.permission == 'granted') {
            navigator.serviceWorker.getRegistration('/').then((registration) => {
              if (registration) {
                registration.showNotification(title, options)
              }
            })
          }
        }
        setNotifications((current) => [...current, { title, ...options }])
      })
    }

    if (mounted && typeof window !== 'undefined') {
      if (messaging.getEnabled()) {
        if (userID) {
          handleRegisterDevice()
        } else {
          messaging.deleteDevice()
        }
      }
    }
  }, [userID, mounted])

  // eslint-disable-next-line no-console
  console.log('[Notifications]', notifications)

  return (
    <MessagingContext.Provider
      value={{
        notifications,
        setNotifications,
        messaging,
      }}
    >
      {children}
    </MessagingContext.Provider>
  )
}
