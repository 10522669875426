import { mutateSession } from '@eq/hooks/useSession'
import { Alert, Form, Input } from 'antd'
import Button from 'antd-button-color'
import { useLottie } from 'lottie-react'
import { Session, User } from 'next-auth'
import { getCsrfToken, getSession, signIn, useSession } from 'next-auth/client'
import Link from 'next/link'
import queryString from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { FiEdit } from 'react-icons/fi'
import { IoMailOutline } from 'react-icons/io5'
import OtpInput from 'react-otp-input'
import { PromiseValue } from 'type-fest'
const returnErrorMessage = (code) => {
  if (!code) return ''
  // https://next-auth.js.org/configuration/pages#error-codes
  // if (code === 'Configuration') return 'There is a problem with the server configuration. Check if your options is correct.'
  if (code === 'AccessDenied') return 'Usually occurs, when you restricted access through the signIn callback, or redirect callback'
  if (code === 'Verification') return 'The token has expired or has already been used'
  if (code === 'OAuthSignin') return 'Error in constructing an authorization URL'
  if (code === 'OAuthCallback') return 'Error in handling the response from an OAuth provider.'
  if (code === 'OAuthCreateAccount') return 'Could not create OAuth provider user in the database.'
  if (code === 'EmailCreateAccount') return 'Could not create email provider user in the database.'
  if (code === 'Callback') return 'Error in the OAuth callback handler route'
  if (code === 'OAuthAccountNotLinked') return 'If the email on the account is already linked, but not with this OAuth account'
  if (code === 'EmailSignin') return 'Sending the e-mail with the verification token failed'
  return 'Error processing your login, please try again.'
}

let initialEmailSent = false

export interface LoginViewProps {
  next?: string
  email?: string
  csrfToken?: PromiseValue<ReturnType<typeof getCsrfToken>>
  onLogin?: (user?: User) => void
  title?: string | null
  autoFocus?: boolean
}

export default function LoginView({ next, email, csrfToken, onLogin, title, autoFocus }: LoginViewProps) {
  const [hasStartedVerification, setHasStartedVerification] = useState(false)
  const [credentials, setCredentials] = useState<{ email: string } | undefined>()
  const [emailLoading, setEmailLoading] = useState(false)
  const [errorCode, setErrorCode] = useState('')
  const [emailForm] = Form.useForm()
  const [session] = useSession()

  useEffect(() => {
    const setToken = async () => {
      emailForm.setFields([{ name: ['csrfToken'], value: await getCsrfToken() }])
    }
    if (!csrfToken) setToken()
  }, [csrfToken, emailForm])

  function setCurrentState(email?: string) {
    if (email) {
      setHasStartedVerification(true)
      setCredentials({ email })
      setEmailLoading(false)
      initialEmailSent = true
    } else {
      setHasStartedVerification(false)
      setCredentials(undefined)
      setEmailLoading(false)
      initialEmailSent = false
    }
  }

  const startVerification = useCallback(
    async ({ email = '' }) => {
      setEmailLoading(true)
      await signIn('email', { email, redirect: false, callbackUrl: next })
        .then((res) => {
          setCurrentState(email)
          return res
        })
        .catch(() => {
          setCurrentState()
          return null
        })
    },
    [next]
  )

  const checkVerification = async ({ verificationCode }) => {
    setEmailLoading(true)
    if (!credentials?.email) {
      setHasStartedVerification(false)
      setCredentials(undefined)
    } else {
      const result = await fetch(`/api/auth/callback/email?email=${credentials.email}&token=${verificationCode}`)
      if (result.ok) {
        const currenSession = await getSession({ triggerEvent: true, event: 'storage' })
        mutateSession(currenSession)
        onLogin?.(currenSession?.user)
      } else {
        const {
          query: { error },
        } = queryString.parseUrl(result.url)
        setErrorCode(error as string)
      }
    }
    setEmailLoading(false)
  }

  useEffect(() => {
    if (email && !initialEmailSent) {
      startVerification({ email })
    }
  }, [email, startVerification])

  return (
    <>
      {session?.user ? (
        <AlreadyLoggedIn next={next} session={session} />
      ) : hasStartedVerification ? (
        <EnterVerificationCode
          email={credentials?.email}
          onSubmit={checkVerification}
          // onResend={startVerification}
          onClear={() => {
            setHasStartedVerification(false)
            setCredentials(undefined)
            setEmailLoading(false)
            setErrorCode('')
          }}
          loading={emailLoading}
          error={returnErrorMessage(errorCode)}
          clearError={() => setErrorCode('')}
        />
      ) : (
        <Layout title={title === undefined ? 'Log in to EezyQuote' : title === null ? undefined : title}>
          <EmailForm
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            onSubmit={startVerification}
            loading={emailLoading}
            error={returnErrorMessage(errorCode)}
            initialValues={{ csrfToken }}
            clearError={() => setErrorCode('')}
            form={emailForm}
          />
        </Layout>
      )}
    </>
  )
}

function Layout({ title = '', children }) {
  return (
    <div className="w-sm max-w-full flex place-items-center justify-center items-center text-center mx-auto">
      <div>
        {title && <h1 className="leading-tight">{title}</h1>}
        {children}
      </div>
    </div>
  )
}

function AlreadyLoggedIn({ next = '', session }: { next?: string; session: Session }) {
  const [text, setText] = useState(next || 'Home')

  const { View } = useLottie({ animationData: require('@eq/lottie/lock-open.json'), loop: false, autoplay: true, height: 200, width: 200 })

  useEffect(() => {
    if (next) setText(next)
    else if (typeof window !== 'undefined') setText(window.location.origin)
  }, [next])

  return (
    <Layout>
      <div className="max-w-full text-center space-y-4 mx-auto">
        <div style={{ marginTop: -20 }}>
          <div className="mx-auto mb-4" style={{ height: 200, width: 200 }}>
            {View}
          </div>
          <div>
            <h2>You have been logged in to EezyQuote, {next ? 'you will now be redirect' : 'you can now close this tab.'}</h2>
            <p>
              {session?.user && (
                <div className="mb-2 text-gray-500 text-xs">
                  Logged In: {session.user?.name ? `${session.user?.name} (${session.user?.email})` : session.user?.email}
                </div>
              )}
              <Link href={next || '/'}>
                <a className="font-bold">{text}</a>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const useStatus = (error) => {
  const [status, setStatus] = useState<'error' | undefined>(error ? 'error' : undefined)

  useEffect(() => {
    if (error) setStatus('error')
    else setStatus(undefined)
  }, [error])

  return [status, setStatus] as const
}

function EmailForm({ onSubmit, loading, error, initialValues, clearError, form, autoFocus = false }) {
  const [status] = useStatus(error)

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      size="large"
      className="max-w-full mx-auto"
      initialValues={initialValues}
      onFieldsChange={() => clearError()}
    >
      <Form.Item name="csrfToken" hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'Email is required!' },
          { type: 'email', message: 'The email is not valid!' },
        ]}
        validateTrigger={['onBlur']}
        validateStatus={status}
        normalize={(val) => {
          return `${val || ''}`.toLowerCase() // ensures lowercase and no spaces
        }}
      >
        <Input
          className="h-12 font-medium"
          type="email"
          placeholder="e.g. example@domain.com"
          prefix={<IoMailOutline className="text-xl mr-1" />}
          disabled={loading}
          autoComplete="email"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
        />
      </Form.Item>
      {error && <Alert type="error" message={error} className="mb-2" />}
      <Button htmlType="submit" block type="primary" loading={loading}>
        Continue
      </Button>
    </Form>
  )
}

const DisablePasswordManager = () => {
  // this is just a trick to ensure the password manager does not try to fill out the OTP CODE input
  return (
    <>
      <input className="hidden" name="disable-pwd-mgr-2" type="password" id="disable-pwd-mgr-2" value="disable-pwd-mgr-2" readOnly />
      <input className="hidden" name="disable-pwd-mgr-1" type="password" id="disable-pwd-mgr-1" value="disable-pwd-mgr-1" readOnly />
      <input className="hidden" name="disable-pwd-mgr-3" type="password" id="disable-pwd-mgr-3" value="disable-pwd-mgr-3" readOnly />
    </>
  )
}

function EnterVerificationCode({ onSubmit, onClear, email, loading, error, clearError }) {
  const [status] = useStatus(error)

  return (
    <Layout title="Enter your security code">
      <p>
        We have sent a security code to{' '}
        <b className="whitespace-nowrap">
          {email} <FiEdit className="text-info cursor-pointer" onClick={() => onClear()} />
        </b>
      </p>
      <DisablePasswordManager />
      <Form onFinish={onSubmit} size="large" className="max-w-xs mx-auto" onFieldsChange={() => clearError()}>
        <Form.Item name="verificationCode" validateStatus={status} rules={[{ required: true, message: 'The security code is required!' }]}>
          <OtpInput
            numInputs={6}
            shouldAutoFocus
            isInputNum
            inputStyle="otp-input ant-input ant-input-lg font-bold text-lg"
            containerStyle="space-x-2 justify-between"
          />
        </Form.Item>
        {error && <Alert type="error" message={error} className="mb-2" />}
        <Button htmlType="submit" block type="info" loading={loading}>
          Confirm
        </Button>
      </Form>
      <style jsx global>{`
        .otp-input {
          width: 35px !important;
        }

        @media only screen and (min-width: 320px) {
          .otp-input {
            width: 40px !important;
          }
        }
        @media only screen and (min-width: 324px) {
          .otp-input {
            width: 44px !important;
          }
        }
      `}</style>
    </Layout>
  )
}
