import { createContext, useContext } from 'react'

export const FeatureFlagsContext = createContext<string[]>([])

export function FeatureFlagsProvider({ features = [] as string[], children }) {
  return <FeatureFlagsContext.Provider value={features || []}>{children}</FeatureFlagsContext.Provider>
}

export function useFeature(name: string) {
  const features = useContext(FeatureFlagsContext)
  return features.includes(name)
}

export function useFeatures() {
  const toolsSectorEnabled = useFeature('tools-sector')

  const features = {
    toolsSectorEnabled,
  }

  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') console.log(features)

  return features
}
