import { useUser } from '@eq/hooks/useUser'
import { loadIntercom } from '@eq/lib/intercom'

import { useEffect } from 'react'

export function useIntercom() {
  const [user] = useUser()

  useEffect(() => {
    loadIntercom(user ? { name: user.name, email: user.email, user_id: user.id, user_hash: user?.user_hash ?? '' } : undefined)
  }, [user])

  return null
}
