import { useSession } from '@eq/hooks/useSession'
import { Modal } from 'antd'
import { User } from 'next-auth'
import React, { useContext } from 'react'
import LoginView, { LoginViewProps } from './LoginView'

const LoginModalContext = React.createContext<(props?: LoginViewProps | undefined) => Promise<User | undefined>>(async () => undefined)

const LoginModalProvider = ({ children }) => {
  const [modal, context] = Modal.useModal()
  const [session] = useSession()

  const loginModal = (props?: LoginViewProps) => {
    return new Promise<User | undefined>((resolve) => {
      const login = modal.confirm({
        title: null,
        content: (
          <div className="-mb-4">
            <LoginView
              onLogin={(user) => {
                resolve(user)
                login.destroy()
              }}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              {...props}
            />
          </div>
        ),
        centered: true,
        closable: true,
        icon: false,
        onCancel: () => resolve(session?.user),
        okButtonProps: { className: 'hidden' },
        cancelButtonProps: { className: 'hidden' },
      })
    })
  }

  return (
    <LoginModalContext.Provider value={loginModal}>
      {children}
      {context}
    </LoginModalContext.Provider>
  )
}

export const useLoginModal = () => {
  const loginModal = useContext(LoginModalContext)
  return loginModal
}

export { LoginModalContext as ModalContext, LoginModalProvider }
