// we use require('polyfill') to avoid vscode organize import moving polyfills down the import chain
// problem: https://github.com/vercel/next.js/discussions/20992#discussioncomment-455676
// proposition: https://github.com/microsoft/TypeScript/issues/41494
require('@eq/polyfills')

import '@algolia/autocomplete-theme-classic'
import AccountLayout from '@eq/components/AccountLayout'
import ScreenSize from '@eq/components/dev-screen-size'
import { FeatureFlagsProvider } from '@eq/components/FeatureFlags'
import GoogleTagManager from '@eq/components/GoogleTagManager'
import { LoginModalProvider } from '@eq/components/LoginView/LoginViewModal'
import { MessagingProvider } from '@eq/components/MessagingProvider'
import { AccountProvider } from '@eq/hooks/useAccount'
import { CordovaProvider } from '@eq/hooks/useCordova'
import { useIntercom } from '@eq/hooks/useIntercom'
import { SessionProvider } from '@eq/hooks/useSession'
import { Locale } from '@eq/i18n'
import { loadIntercom } from '@eq/lib/intercom'
import '@eq/styles/antd.css'
import '@eq/styles/nprogress.css'
import '@eq/styles/tailwind.css'
import { fetcher, swrFetcher } from '@eq/utils/fetcher'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import '@reach/skip-nav/styles.css'
import { ConfigProvider, notification } from 'antd'
import 'focus-visible/dist/focus-visible'
import { AppProps, NextWebVitalsMetric } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Router from 'next/router'
import NextScript from 'next/script'
import nprogress from 'nprogress'
import { FunctionComponent, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import useSWR, { SWRConfig } from 'swr'

const ReactIconsProvider = dynamic(() => import('@eq/components/ReactIconsProvider'), { ssr: false })

config.autoAddCss = false

// const CoviewScript = dynamic(() => import('@eq/utils/coview'), { ssr: false })

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
  // Use `window.gtag` if you initialized Google Analytics as this example:
  // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_document.js
  window?.gtag?.('event', name, {
    event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  })
}

loadIntercom()

Router.events.on('routeChangeStart', () => {
  nprogress.start()
})
Router.events.on('routeChangeComplete', (_url) => {
  // gtag.pageview(url)

  nprogress.done()
})
Router.events.on('routeChangeError', () => nprogress.done())

const AntdConfigProvider = ({ form, ...props }) => {
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
  }
  return <ConfigProvider form={{ ...form, validateMessages }} {...props} />
}

interface ExtendedAppProps extends Omit<AppProps, 'Component' | 'pageProps'> {
  Component: AppProps['Component'] & { layout?: FunctionComponent<any> }
  pageProps: AppProps['pageProps'] & { slug?: string; layout?: 'team' | 'user'; features?: string[] }

  locale: Locale
  messages: Record<string, string>
  isMobile: boolean
  err?: any
}

const getLayout = (layout?: ExtendedAppProps['pageProps']['layout']) => {
  if (layout === 'team') return AccountLayout.Team
  if (layout === 'user') return AccountLayout.User
  return undefined
}

const InitiateScripts = () => {
  useIntercom()

  return null
}

const checkAppVersion = async (path: string) => {
  const appVersion = await fetch(path)
    .then((res) => res.json())
    .then((res) => (res?.version as string) || '')
    .catch((err) => {
      console.error(err)
      return ''
    })

  if (typeof window !== 'undefined' && appVersion) {
    const sessionVersion = sessionStorage.getItem('app-version') || ''
    if (!sessionVersion) sessionStorage.setItem('app-version', appVersion)
    else if (sessionVersion !== appVersion) {
      sessionStorage.setItem('app-version', appVersion)
      notification.info({
        message: 'Update ready the app will restart now.',
        duration: 2,
        onClose: () => {
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration('/').then((registration) => {
              if (registration) {
                registration.update().then(() => {
                  window.location.reload()
                })
              }
            })
          } else {
            window.location.reload()
          }
        },
      })
    }
  }
}

const GlobalStyles = ({ children }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const root = document.documentElement
      const vh = root.getBoundingClientRect().height || window.innerHeight
      root.style.setProperty(`--vh`, vh + 'px')
    }
  }, [])

  return (
    <>
      {children}
      <style jsx global>{`
        html {
          scroll-behavior: smooth;
          height: -webkit-fill-available;
        }
        body {
          min-height: 100vh;
          min-height: -webkit-fill-available;
        }
      `}</style>
    </>
  )
}

const FallbackLayout = ({ children }) => children

const Application = (props: ExtendedAppProps) => {
  const { Component, pageProps, messages, locale = 'en', err = 404 } = props
  const [features, setFeatures] = useState(pageProps?.features || [])

  useEffect(() => {
    const getFeatures = async () => {
      const result = await fetcher('/api/feature-flags')
      setFeatures(result.data)
    }
    getFeatures()
  }, [])

  useSWR('/app-version.json', checkAppVersion)

  if (pageProps.layout === 'none')
    return (
      <GlobalStyles>
        <Component {...pageProps} err={err} />
      </GlobalStyles>
    )

  const Layout = Component.layout || getLayout(pageProps.layout) || FallbackLayout

  return (
    <GlobalStyles>
      <SWRConfig
        value={{
          fetcher: swrFetcher,
        }}
      >
        <FeatureFlagsProvider features={features}>
          <SessionProvider
            session={pageProps.session}
            options={{
              clientMaxAge: 60, // Re-fetch session if cache is older than 60 seconds
            }}
          >
            <AccountProvider>
              <LoginModalProvider>
                <CordovaProvider>
                  <Head>
                    <title>EezyQuote</title>
                    <meta
                      name="viewport"
                      content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                    />
                    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                    <link rel="manifest" href="/manifest.json" />
                    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ffc40d" />
                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta name="theme-color" content="#ffffff" />
                    <meta name="msapplication-TileColor" content="#ffc40d" />
                    <meta name="msapplication-config" content="/browserconfig.xml" />

                    <link rel="shortcut icon" href="/favicon.ico" />
                  </Head>
                  <NextScript
                    src={process.env.NODE_ENV === 'development' ? '' : 'https://zhpmzx0sbsbv.statuspage.io/embed/script.js'}
                    strategy="lazyOnload"
                  />
                  {/* <CoviewScript /> */}
                  <InitiateScripts />
                  <IntlProvider
                    messages={messages}
                    locale={locale}
                    defaultRichTextElements={{
                      highlight: (...chunks) => <span className="text-warning">{chunks}</span>,
                      info: (...chunks) => <span className="text-info">{chunks}</span>,
                      b: (...chunks) => <b>{chunks}</b>,
                      m: (...chunks) => <b className="font-medium">{chunks}</b>,
                      p: (...chunks) => <p>{chunks}</p>,
                      br: () => <br />,
                      i: (...chunks) => <i className="italic">{chunks}</i>,
                    }}
                  >
                    <MessagingProvider>
                      <AntdConfigProvider locale={{ locale: locale }} form={{ requiredMark: 'optional' }}>
                        <ReactIconsProvider>
                          <Layout slug={pageProps?.slug}>
                            <GoogleTagManager>
                              <Component {...pageProps} err={err} />
                            </GoogleTagManager>
                            <ScreenSize />
                          </Layout>
                        </ReactIconsProvider>
                      </AntdConfigProvider>
                    </MessagingProvider>
                  </IntlProvider>
                </CordovaProvider>
              </LoginModalProvider>
            </AccountProvider>
          </SessionProvider>
        </FeatureFlagsProvider>
      </SWRConfig>
    </GlobalStyles>
  )
}

export default Application
