import { cn } from '@eq/utils/classnames'
import { Tag } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useState } from 'react'

const twTextSize = 'leading-[12px] text-[8px]'

const Title = ({ children }) => <span className={cn('text-inherit mr-2', twTextSize)}>{children}:</span>

const AntBreakpoints = () => {
  const screens = useBreakpoint()
  return (
    <div>
      <Title>ant</Title>
      {Object.entries(screens)
        .filter((screen) => !!screen[1])
        .map((screen) => (
          <Tag color="blue" key={screen[0]} className={twTextSize}>
            {screen[0]}
          </Tag>
        ))}
    </div>
  )
}

const TailwindBreakpoints = () => {
  const breakpoints = [
    { label: 'xs', class: 'sm:hidden' },
    { label: 'sm', class: 'hidden sm:block md:hidden' },
    { label: 'md', class: 'hidden md:block lg:hidden' },
    { label: 'lg', class: 'hidden lg:block xl:hidden' },
    { label: 'xl', class: 'hidden xl:block' },
  ]
  return (
    <div className="whitespace-nowrap flex">
      <Title>tw</Title>
      {breakpoints.map((bp) => (
        <Tag color="purple" key={bp.label} className={cn('font-bold', twTextSize, bp.class)}>
          {bp.label}
        </Tag>
      ))}
    </div>
  )
}

export default function ScreenSize() {
  const [toggled, setToggled] = useState(false)
  if (process.env.NODE_ENV !== 'development') return null
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={() => setToggled((flag) => !flag)}
      className={cn(
        'fixed right-0 bottom-0 z-[999999] flex items-center justify-center h-5 backdrop-blur rounded-tl-md axe-exclude p-2  no-print ring-b-0 ring-r-0',
        twTextSize,
        {
          'opacity-40 hover:opacity-100 text-gray-500 hover:text-gray-50  hover:bg-black-300 hover:bg-blur-dark': !toggled,
          'opacity-100 ring-2 text-gray-50 ring-blue-500 bg-black bg-blur-dark': toggled,
        }
      )}
    >
      <AntBreakpoints />
      <TailwindBreakpoints />
    </div>
  )
}
